#searchInput:focus {
  border-radius: 0;
  outline-width: 0;
  border-color: #eee;
}

#userLink:hover {
  background-color: #CEE;
  border-radius: 5px;
}
