
#divButton {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

#divButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

#divButton:active {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.link {
   color:black;
   text-decoration: none;
   background-color: none;
}
