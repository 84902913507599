.Testimonials__card {
  flex-direction: column;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
}

.Testimonials__card-content {
  flex-direction: column;
  height: 100%;
  padding: 1.8rem;
}

.Testimonials__avatar-wrapper {
  margin: 0 auto;
}

.Testimonials__quote {
  margin-top: 30px;
}

.Testimonials__info {
  margin-top: auto;
  padding-top: 40px;
}

.Testimonials__company {
  margin-top: 3px;
}
