

#dropzone:hover {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  // border-radius: 5px;
}


#dropzone {
  width : 100%;
  height : 20%;
  border : 1px solid black;
}

.border {
  // width : 100%;
  // height : 20%;
  border : 1px dotted black;
}


// .border:hover {
//   // width : 100%;
//   // height : 20%;
//   border : 2px solid black;
// }

// .border:active {
//   // width : 100%;
//   // height : 20%;
//   border : 2px solid black;
// }
//
// .border:focus {
//   // width : 100%;
//   // height : 20%;
//   border : 2px solid black;
// }

.link {
   color:black;
   text-decoration: none;
   background-color: none;
}
